import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config } from "@fortawesome/fontawesome-svg-core";

import {
    faGithub,
    faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

import {
    faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

// Fixes icons flashing in size
config.autoAddCss = false;

const _iconMap = {
    // Brands
    'github': faGithub,
    'linkedin': faLinkedinIn,

    // Free
    'envelope': faEnvelope,
};

const Icon = ({icon}) => (
    <div className="icon">
        <FontAwesomeIcon icon={_iconMap[icon]}/>
    </div>
);

export default Icon;