import React from "react";

import Header from "../components/Header";
import Layout from "../components/Layout";
import Footer from "../components/Footer";

import Icon from "../components/Icon";

const socialLinks = [
    {
        target: '_blank',
        icon: 'github',
        href: 'https://github.com/hbackman',
    },
    {
        target: '_blank',
        icon: 'linkedin',
        href: 'https://www.linkedin.com/in/hampus-b-2b9584b8',
    },
    {
        target: '_self',
        icon: 'envelope',
        href: 'mailto:hampus@wouix.se',
    },
];

class IndexPage extends React.Component {

    getIcons () {
        return socialLinks.map(link => (
            <a href={link.href} target={link.target}>
                <Icon icon={link.icon}/>
            </a>
        ));
    }

    render () {
        return (
            <React.Fragment>
                <Header/>
                <Layout>
                    <div className="info">
                        <div className="info--title">
                            I'm Hampus,<br/>
                            a software engineer.
                        </div>
                        <div className="info--text">
                            I build efficient, clean software, using modern<br/>
                            proven technologies.
                        </div>
                        <div className="info--social">
                            { this.getIcons() }
                        </div>
                    </div>
                </Layout>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default IndexPage;
